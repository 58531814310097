<template>
  <v-app class="zone">
    <section>
      <v-row>
        <h1>Destination Zones</h1>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-btn outlined dense @click="openZoneDialog">
                <i class="mr-3 fa fa-plus"></i> Add Zone
              </v-btn>
              <!-- <v-btn class="ml-3" outlined dense>
                <i class="mr-3 fa fa-trash"></i> Delete
              </v-btn>-->
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <h5 class="font-weight-bold ml-3">Zones</h5>
                  <v-list dense>
                    <v-list-item-group v-model="item" color="primary">
                      <v-list-item
                        v-for="(item, i) in regions"
                        :key="i"
                        @change="onZoneSelect(item)"
                      >
                        <v-list-item-icon class="mt-3">
                          <v-icon class="icon" v-text="'fa fa-crown'"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="ml-2" v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon @click="deleteRegion(item.id)">
                            <v-icon class="icon" color="grey lighten-1">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col cols="8">
                  <v-col sm="12" md="6" lg="6">
                    <!-- <label for="zone_name">Zone Name</label> -->
                    <h3>{{region.title}}</h3>
                    <!-- <v-text-field label="Zone Name" outlined dense v-model="zone"></v-text-field> -->
                  </v-col>
                  <v-row>
                    <v-col sm="6" md="6" lg="6" v-if="show_country">
                      <v-col sm="12" md="12" lg="12" class="text-center">
                        <v-btn outlined dense @click="openCountryDialog">
                          <i class="mr-3 fa fa-plus"></i> Add Country
                        </v-btn>
                        <!-- <v-btn outlined dense class="ml-2">
                          <i class="mr-3 fa fa-plus"></i> Clear List
                        </v-btn>-->
                      </v-col>
                      <v-col sm="12" md="12" lg="12">
                        <h4 class="shadow">Countries</h4>
                      </v-col>
                      <v-col sm="12" md="12" lg="12">
                        <div v-if="regionCountries.length>0">
                          <span
                            class="badge badge-secondary m-1"
                            v-for="(item, i) of regionCountries"
                            :key="i"
                          >
                            {{item.country.toUpperCase()}}
                            <i
                              @click="deleteCountry(item.id)"
                              class="ml-1 fa fa-trash"
                            ></i>
                          </span>
                        </div>
                        <div class="text-center" v-else>
                          <span>No countries found</span>
                        </div>
                      </v-col>
                    </v-col>
                    <v-col sm="6" md="6" lg="6" v-if="show_state">
                      <v-col sm="12" md="12" lg="12" class="text-center">
                        <v-btn outlined dense @click="openStateDialog">
                          <i class="mr-3 fa fa-plus"></i> Add State
                        </v-btn>
                        <!-- <v-btn outlined dense class="ml-2">
                          <i class="mr-3 fa fa-plus"></i> Clear List
                        </v-btn>-->
                      </v-col>
                      <v-col sm="12" md="12" lg="12">
                        <h4 class="shadow">States</h4>
                        <v-col sm="12" md="12" lg="12">
                          <div v-if="regionStates.length>0">
                            <span
                              class="badge badge-secondary m-1"
                              v-for="(item, i) of regionStates"
                              :key="i"
                            >
                              {{item.state.toUpperCase()}}
                              <i
                                @click="deleteState(item.id)"
                                class="ml-1 fa fa-trash"
                              ></i>
                            </span>
                          </div>
                          <div class="text-center" v-else>
                            <span>No states found</span>
                          </div>
                        </v-col>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section class="region_dialog">
      <v-dialog scrollable v-model="region_dialog" max-width="600">
        <v-card>
          <v-card-title class="headline">Add New Zone</v-card-title>

          <v-card-text class="mt-4">
            <v-text-field label="Zone Name" v-model="region.title" outlined dense></v-text-field>
            <span class="text-danger" v-if="$v.region.title.$error">
              <strong>Title is required field</strong>
            </span>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="closeZoneDialog">Close</v-btn>

            <v-btn color="green darken-1" text @click="saveRegion">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
    <section class="country_dialog">
      <v-dialog scrollable v-model="country_dialog" max-width="600">
        <v-card>
          <v-card-title class="headline">Add New Country Under {{region.title}}</v-card-title>

          <v-card-text class="mt-4">
            <v-select
              :items="countries"
              item-text="title"
              item-value="id"
              v-model="country_ids"
              multiple
              chips
              label="Select Country"
              outlined
              dense
            ></v-select>
            <span class="text-danger" v-if="$v.country_ids.$error">
              <strong>Country is required.</strong>
            </span>
          </v-card-text>
          <!-- <pre>{{country_ids}}</pre> -->
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="closeCountryDialog">Close</v-btn>

            <v-btn color="green darken-1" text @click="saveCountry">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>

    <section class="state_dialog">
      <v-dialog scrollable v-model="state_dialog" max-width="600">
        <v-card>
          <v-card-title class="headline">Add New State</v-card-title>

          <v-card-text class="mt-4">
            <v-select
              :items="countries"
              item-text="title"
              item-value="title"
              v-model="selected_country"
              label="Select Country"
              outlined
              dense
              @change="getStates()"
            ></v-select>

            <v-select
              :items="states"
              item-text="title"
              item-value="id"
              v-model="state_ids"
              multiple
              chips
              label="Select States"
              outlined
              dense
            ></v-select>
          </v-card-text>
          <!-- <pre>{{state_ids}}</pre> -->
          <v-card-actions>
            <v-btn color="green darken-1" text @click="closeStateDialog">Close</v-btn>

            <v-btn color="green darken-1" text @click="saveState">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import EcommerceShippingRegionService from "@/services/store/shipping/EcommerceShippingRegionService";
import EcommerceShippingRegionCountryService from "@/services/store/shipping/EcommerceShippingRegionCountryService";
import EcommerceShippingRegionStateService from "@/services/store/shipping/EcommerceShippingRegionStateService";
import CountryService from "@/services/address/CountryService";
import StateService from "@/services/address/StateService";

const country = new CountryService();
const state = new StateService();
const regionService = new EcommerceShippingRegionService();
const regionCountryService = new EcommerceShippingRegionCountryService();
const regionStateService = new EcommerceShippingRegionStateService();

export default {
  validations: {
    region: {
      title: {
        required
      }
    },
    country_ids: { required },
    state_ids: { required }
  },
  data() {
    return {
      country_ids: [],
      state_ids: [],
      show_country: false,
      show_state: false,
      region_dialog: false,
      country_dialog: false,
      state_dialog: false,
      selected_country: null,
      countries: [],
      states: [],
      domainname: null,
      region: { title: null, is_active: 1 },
      regionCountries: [],
      regionStates: [],
      regions: []
    };
  },
  mounted() {
    this.domainname = this.$route.params.domainname;
    this.getRegions();
  },
  watch: {},
  methods: {
    openZoneDialog() {
      this.region = { title: null };
      this.region_dialog = true;
    },
    closeZoneDialog() {
      this.region_dialog = false;
    },
    onZoneSelect(item) {
      this.region = item;
      this.show_country = true;
      this.show_state = true;
      this.getRegionCountries();
      this.getRegionStates();
    },

    onCountrySelect(item) {
      this.country = item.title;
    },

    closeCountryDialog() {
      this.country_dialog = false;
    },

    openCountryDialog() {
      this.country_dialog = true;
      this.getCountries();
    },

    closeStateDialog() {
      this.state_dialog = false;
    },

    openStateDialog() {
      this.getCountries();
      this.state_dialog = true;
    },

    getCountries() {
      country.all().then(res => {
        this.countries = res.data;
      });
    },

    getStates() {
      state.all(this.selected_country).then(res => {
        this.states = res.data;
      });
    },

    // Region Starts Here
    getRegions() {
      regionService
        .paginate(this.domainname)
        .then(response => {
          this.regions = response.data.data;
        })
        .catch(error => {
          // console.log(error);
        });
    },

    deleteRegion(id) {
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        regionService
                            .delete(this.domainname, id)
                            .then(response => {
                                this.regions = response.data.data;
                                this.resetRegion();
                            })
                            .catch(error => {
                                // console.log(error);
                            });
                    }
                }
            }
        )

    },
    saveRegion() {
      this.$v.region.$touch();
      if (this.$v.region.$error) {
        setTimeout(() => {
          this.$v.region.$reset();
        }, 3000);
      } else {
        regionService
          .create(this.domainname, this.region)
          .then(response => {
            this.resetRegion();
          })
          .catch(error => {
            // console.log(error);
          });
      }
    },
    resetRegion() {
      this.region = { title: null };
      this.$v.region.$reset();
      this.closeZoneDialog();
      this.getRegions();
    },
    // Region Ends Here

    // Country Starts from Here
    getRegionCountries() {
      regionCountryService
        .paginate(this.domainname, this.region.id)
        .then(response => {
          this.regionCountries = response.data.data;
        })
        .catch(error => {
          // console.log(error);
        });
    },
    deleteCountry(id) {
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        regionCountryService
                            .delete(this.domainname, this.region.id, id)
                            .then(response => {
                                this.resetRegionCountry();
                            })
                            .catch(error => {
                                // console.log(error);
                            });
                    }
                }
            }
        )

    },
    saveCountry() {
      this.$v.country_ids.$touch();
      if (this.$v.country_ids.$error) {
        setTimeout(() => {
          this.$v.country_ids.$reset();
        }, 3000);
      } else {
        let data = { country_ids: this.country_ids };
        regionCountryService
          .create(this.domainname, this.region.id, data)
          .then(response => {
            this.resetRegionCountry();
          })
          .catch(error => {
            // console.log(error);
          });
      }
    },

    resetRegionCountry() {
      this.closeCountryDialog();
      this.country_ids = [];
      this.$v.country_ids.$reset();
      this.getRegionCountries();
    },

    // Country Ends from Here

    // Sate Starts from Here
    getRegionStates() {
      regionStateService
        .paginate(this.domainname, this.region.id)
        .then(response => {
          this.regionStates = response.data.data;
        })
        .catch(error => {
          // console.log(error);
        });
    },
    deleteState(id) {
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        regionStateService
                            .delete(this.domainname, this.region.id, id)
                            .then(response => {
                                this.resetRegionState();
                            })
                            .catch(error => {
                                // console.log(error);
                            });
                    }
                }
            }
        )

    },

    saveState() {
      this.$v.state_ids.$touch();
      if (this.$v.state_ids.$error) {
        setTimeout(() => {
          this.$v.state_ids.$reset();
        }, 3000);
      } else {
        let data = { state_ids: this.state_ids };
        regionStateService
          .create(this.domainname, this.region.id, data)
          .then(response => {
            this.resetRegionState();
          })
          .catch(error => {
            // console.log(error);
          });
      }
    },

    resetRegionState() {
      // this.region = { title: null };
      // this.$v.region.$reset();

      this.closeStateDialog();
      this.state_ids = [];
      this.$v.state_ids.$reset();
      this.getRegionStates();
    }
    // State Ends from Here
  }
};
</script>
<style scoped>
.zone h1 {
  font-weight: 600;
}
.shadow {
  background: grey;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
}
.icon {
  font-size: 18px !important;
}
</style>
