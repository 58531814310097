import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class EcommerceShippingRegionStateService {

      #api;
      constructor() {
            this.#api = API_URL + 'user/website';
      }

      paginate(domainName, regionId) {
            let url = `${this.#api}/${domainName}/shipping-region/${regionId}/state`;
            return apiService.get(url)
      }

      create(domainName, regionId, data) {
            let url = `${this.#api}/${domainName}/shipping-region/${regionId}/state`;
            return apiService.post(url, data)
      }

      delete(domainName, regionId, id) {
            let url = `${this.#api}/${domainName}/shipping-region/${regionId}/state/${id}`;
            return apiService.delete(url)
      }
}
